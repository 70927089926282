<template>
    <v-container fluid>
        <v-row>
             <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
             <v-col>
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="company_id" 
                                    item-text="company_id" 
                                    label="Company ID"
                                    @change="(event) => getEmployees(event)"
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="employee" 
                                    :items="companys" 
                                    default="" 
                                    item-value="idemployee" 
                                    item-text="idemployee" 
                                    label="Employee ID" 
                                    clearable 
                                    @change="(event) => getCompany(event)"
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="namex" 
                                    :items="companys" 
                                    default="" 
                                    item-value="name" 
                                    item-text="name" 
                                    label="Name" 
                                    clearable  
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-3" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
              <v-row class="mt-2" style="padding-bottom:50px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                    <v-card-text>
                         <h5>Result</h5>
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="menu_headers"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            class="elevation-1"
                            >  
                           <template v-slot:[`item.actionssss`]="{ item }">
                            <div class="d-flex">
                                <v-avatar size="45px" item class="ml-2">
                                  <v-img :src="item.file_name ? item.file_name : '../../../public/images/default-user.png'"></v-img>
                                </v-avatar>
                            </div>
                            </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                      <v-chip
                                        class="ma-2"
                                        color="success"
                                        >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="upload(item)" v-on="on">
                                                    mdi-upload
                                                </v-icon>
                                            </template>
                                            <span> Upload</span>
                                        </v-tooltip>
                                         Upload
                                      </v-chip>
                                     </template>
                                     <template v-slot:[`item.actionss`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small color="blue" class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span> Show File</span>
                                        </v-tooltip>
                                 </template>
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="400px">                
            <v-card>
                <v-progress-linear
                        :active="loading_dialog"
                        :indeterminate="loading_dialog"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Upload File Image</v-card-title>
                <v-card-text>
                    <v-container fluid>
                    <v-row>
                     <v-spacer></v-spacer>
                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept="image/*">
                    </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-col cols="6" xs="12" sm="12" md="6">
                    <v-btn
                    class="mr-2 text-white"
                    color="#053d76"
                    elevation="2"
                    large
                    @click="saveImage()"
                    >Save</v-btn>
                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <v-dialog
        v-model="dialog_file_doc"
        persistent
        max-width="1300px"
        >
        <v-card class="p-2 rounded-l">
            <v-card-title class="text-center">
                <v-row>
                    <h4
                    class="text-center"
                    style="
                    font-weight: 600;
                    color: #053d76;
                    margin-bottom: 15px;
                    margin-top: 2px;">
                    USER HRIS KMB
                    </h4>
                <v-divider
                    style="
                    border-top: 3px solid #0078d4;
                    margin-top: 5px;"></v-divider>
                </v-row>
            </v-card-title>
             <v-card-text>
                <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                    <v-row>
                        <v-col class="col-12" sm="4" md="4">
                            <v-card class="elevation-1 rounded-5">
                                <v-card-text>
                                    <v-row justify="center" align="center" class="mt-12" v-if="userModel === null">
                                        <v-col cols="3">
                                        </v-col>
                                    </v-row>
                                    <v-img v-for="file in doc_files" :key="file.file_name" :src="file.file_name"></v-img>
                                    <p class="css-bqy72x mt-5">Besar file: maksimum (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="col-12" sm="8" md="8">
                        <v-container fluid>
                            <v-col cols="12">
                                <h5 class="text-center" 
                                style="
                                font-weight: 600;
                                color: #053d76;
                                margin-bottom: 45px;
                                margin-top: 2px;"><span>Profile User Info</span></h5>
                            </v-col>
                            <v-row>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Company ID<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="company_id"
                                    :items="menu_headers"
                                    item-value="company_id" 
                                    item-text="company_id" 
                                    disabled 
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">ID Employee<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="idemployee"
                                    :items="menu_headers"
                                    item-value="idemployee"
                                    hide-details=true
                                    item-text="idemployee" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Jabatan<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="jabatan"
                                    :items="menu_headers"
                                    item-value="jabatan"
                                    hide-details=true
                                    item-text="jabatan" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Name<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="name"
                                    :items="menu_headers"
                                    item-value="name"
                                    hide-details=true
                                    item-text="name" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Pendidikan<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="educ"
                                    :items="menu_headers"
                                    item-value="educ"
                                    hide-details=true
                                    item-text="educ" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Agama<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="religion"
                                    :items="menu_headers"
                                    item-value="religion"
                                    hide-details=true
                                    item-text="religion" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">No Hanphone<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="phone"
                                    :items="menu_headers"
                                    item-value="phone"
                                    hide-details=true
                                    item-text="phone" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">NPWP<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="npwp"
                                    :items="menu_headers"
                                    item-value="npwp"
                                    hide-details=true
                                    item-text="npwp" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Email<span class="red--text"></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="email"
                                    :items="menu_headers"
                                    item-value="email"
                                    hide-details=true
                                    item-text="email" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="12">
                                <h6 class="blue-lcd mb-1">Alamat<span class="red--text"></span></h6>
                                <v-autocomplete
                                     dense
                                    solo
                                    clearable
                                    v-model="address1"
                                    :items="menu_headers"
                                    item-value="address1"
                                    hide-details=true
                                    item-text="address1" 
                                    disabled
                                ></v-autocomplete> 
                            </v-col>
                               <v-row class="mt-12">
                                            <v-col cols="12">
                                                <v-alert
                                                icon="mdi-shield-lock-outline"
                                                prominent
                                                text
                                                color="red"
                                                >
                                                Cek kembali data setiap user pastikan data benar dan tidak ada kesalahan input data user
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                </v-row>
                        </v-container>
                        </v-col>
                        </v-row>
                        </v-container>
                    </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l mr-2 text-white"  color="red" @click.stop="dialog_file_doc=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
         return {  
            loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'E-Kencana',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'HRIS',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'List of Employee',
                    disabled: true,
                }
            ],
            menu_headers:[],
            headers:[
                {
                value: 'actionssss',
                width:'2px',
                align:'left'},
                {
                text: 'Company ID',
                align: 'start',
                value: 'company_id', 
                align:'center'},
                { text: 'Office ID', value: 'office_id' , align:'center'},
                { text: 'Dept ID', value: 'dept_id' , align:'center'},
                { text: 'Name', value: 'name' , align:'left'},
                { text: 'Image', value: 'actions' , align:'center'},
                { text: 'Detail', value: 'actionss' , align:'center'},
            ],
            header_file_items:[
            {
                text: 'Company ID',
                align: 'start',
                sortable: true,
                value: 'company_id',
            },
            { text: 'Office ID', value: 'office_id' },
            { text: 'File Name', value: 'file_name' },
            { text: 'Action', value: 'actions', sortable: false }
            ],
            doc_files: [],
            file_name:[],
            userModel:[],
            offices:[],
            Employees:[],
            names:[],
            Addresses:[],
            Countrys:[],
            companys:[],
            dialog: false,
            dialog_file_doc: false,
            loading_dialog: false,
            office:'',
            company_id:'',
            jabatan:'',
            idemployee:'',
            employee:'',
            Company:'',
            name:'',
            namex:'',
            educ:'',
            address1:'',
            npwp:'',
            email:'',
            phone:'',
            religion:'',
            address:'',
            country:'',
            imageModel: '',
            imageUrl: '',
            userModel:'',
            userModel: null,
            showDetail:false
         }
    },
    mounted(){
        this.getCompany()
        this.getOffice()
        this.getNames()
       // this.getFileDocImage()
    },
    methods: {
    close(){
          this.dialog = false
        },
    upload(item){
        this.dialog = true;
       // console.log(item)
        this.company_id = item
        this.office_id = item
        this.dept_id = item
        this.division_id = item
        this.group_id = item
        this.educ = item
        this.npwp = item
        this.idemployee = item
        this.type = item
        this.section_id = item
        this.email = item
        },

    handleFileUpload(){
        this.file = this.$refs.file.files[0];
        //console.log(this.file);
        }, 
    saveImage(item){
            this.loading_dialog = true
            let formData = new FormData();
            formData.append('company_id', this.company_id.company_id);
            formData.append('office_id', this.office_id.office_id);
            formData.append('dept_id', this.dept_id.dept_id);
            formData.append('division_id', this.division_id.division_id);
            formData.append('group_id', this.group_id.group_id);
            formData.append('idemployee', this.idemployee.idemployee);
            formData.append('section_id', this.section_id.section_id);
            formData.append('file', this.file);
            axios.post(`${process.env.VUE_APP_URL}/api/kmb/listemploye/upload`, formData , 
            { 
              headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                this.loading_dialog = false
                this.dialog = false
                this.file = ''
                this.snackbar = {
                  color: "success",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Success",
                  text: "Successfully Upload File",
                  visible: true
                };
                this.getPullData()
            }).catch(err => {
                this.loading_dialog = false
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: err.response.data.message,
                  visible: true
                };
            })

        },
        showFileDetail(item){
        // console.log(item);
          this.company_id = item
          this.office_id = item
          this.idemployee = item
          this.dept_id = item
          this.division_id = item
          this.religion = item
          this.section_id = item
          this.jabatan = item
          this.name = item
          this.educ = item
          this.email = item
          this.npwp = item
          this.phone = item
          this.address1 = item
          this.dialog_file_doc = true
          this.getFileDocImage()
          this.getIndex()
        },
         async getFileDocImage(item){
          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/filed?search=${this.$store.state.text}&company_id=${this.company_id ? this.company_id.company_id : ""}&office_id=${this.office_id ? this.office_id.office_id : ""}&idemployee=${this.idemployee ? this.idemployee.idemployee : ""}&dept_id=${this.dept_id ? this.dept_id.dept_id : ""}&division_id=${this.division_id ? this.division_id.division_id : ""}&section_id=${this.section_id ? this.section_id.section_id : ""}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            this.doc_files = res.data;
            this.doc_files = this.doc_files.map(file => {
            file.file_name = process.env.VUE_APP_URL + '/img/hrd/emp/pas_foto/' + file.file_name;
            return file;
        });
    });
        
    },  
    async getIndex(item){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye?search=${this.$store.state.text}&company_id=${this.company_id ? this.company_id.company_id : ""}&jabatan=${this.jabatan ? this.jabatan.jabatan : ""}&name=${this.namex ? this.namex.namex : ""}&educ=${this.educ ? this.educ.educ : ""}&religion=${this.religion ? this.religion.religion : ""}&phone=${this.phone ? this.phone.phone : ""}&npwp=${this.npwp ? this.npwp.npwp : ""}&email=${this.email ? this.email.email : ""}&address1=${this.address1 ? this.address1.address1 : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
            this.loading = false
             //console.log(item.file.name)
                    this.menu_headers = res.data.datas
                    this.menu_headers = this.menu_headers.map(file => {
                    file.file_name = process.env.VUE_APP_URL + '/img/hrd/emp/pas_foto/' + file.file_name;
                    return file;
            });
                
                
            });
        },

    async getPullData(){
        this.loading = true
        if (this.office == '' || null ) {
                this.loading_dialog = false
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please Input Company ID",
                  visible: true
                };
        
        }else{
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo?search=${this.$store.state.text}&company_id=${this.office ? this.office : ""}&idemployee=${this.employee ? this.employee : ''}&name=${this.namex ? this.namex : ""}&country=${this.country ? this.country : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
               // console.log(this.file_name)
                this.menu_headers = res.data.datas
                this.menu_headers = res.data.datas
                this.menu_headers = this.menu_headers.map(file => {
                file.file_name = process.env.VUE_APP_URL + '/img/hrd/emp/pas_foto/' + file.file_name;
                    return file;
            });
                
            });
        }

        },

        async getOffice(){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // this.loading = false
                //console.log(res.data)
                this.offices = res.data.datas
            })
        },

        async getCompany(){
            //this.loading = false
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/permission/GetCompany?search=${this.$store.state.text}&idemployee=${this.employee ? this.employee : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // this.loading = true
               //console.log(res.data)
                this.companys = res.data.datas
            })
        },  
        async getEmployees(){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.Employees = res.data.datas
            })
        },

        async getNames(){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.names = res.data.datas
            })
        },
        
        async getAddress(){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.Addresses = res.data.datas
            })
        }, 

        async getCountry(){
           // this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/listemploye/getDataemplo`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.Countrys = res.data.datas
            })
        }, 
        
    }

}
</script>

<style>

</style>